import { type GetHookResponse } from '../hooks'
import { eventKey } from '../keys'
import { useTantalimQuery } from '../query'
import { type API, type CalendarEventDataWithDetails, type CalendarEventId, CURRENT_VERSION } from '@terros/common'

export function useCalendarEvent(eventId: CalendarEventId): GetHookResponse<CalendarEventDataWithDetails> {
  return useTantalimQuery<API.CalendarEventGetInput, API.CalendarEventGetSuccess, CalendarEventDataWithDetails>({
    url: '/calendar/event/get',
    queryKey: eventKey(eventId),
    input: { eventId, version: CURRENT_VERSION },
    convertResponse: (data) => data.event,
  })
}
